export default {
  QR_ICON: "https://cdn-stage.itscredible.com/qr-code.jpg",
  TEMPLATE_DEFAULT_DIMENSION: {
    Certificate: {
      width: 1123,
      height: 794,
      scale: 1.75,
    },
    Badge: {
      width: 794,
      height: 794,
      scale: 1.75,
    },
  },
  FONTS_FOR_TEMPLATE: [
    "Adobe Clean",
    "Arial",
    "Arial Narrow",
    "AlegreyaSC",
    "Aleo",
    "Amaranth",
    "Antonio",
    "Asap",
    "Avenir",
    "Bebas Neue",
    "Cambo",
    "Georgia",
    "Great Vibes",
    "Helvetica",
    "Lato",
    "Montserrat",
    "Muli",
    "Open Sans",
    "Oswald",
    "Playfair Display",
    "Poppins",
    "Raleway",
    "Roboto",
    "Roboto Condensed",
    "Rockwell",
    "Segoe UI",
    "Times New Roman",
    "Verdana",
  ],
  DEFAULT_TEMPLATE_BG: "#FFFFFF",
  WORKSPACE_WIDTH_PROPORTION: {
    toolbar: "30%",
    canvasArea: "70%",
  },
};
